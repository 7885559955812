import React,{useEffect,useState} from 'react';
// import { Tag,Card,PageHeader } from 'antd';
// import { Form, Input, Button, Checkbox } from 'antd';
// import './index.css'
import {
    Redirect,useHistory ,useParams,useLocation
} from "react-router-dom";
import queryString from 'query-string'
import { browserName, browserVersion } from "react-device-detect";


import api from  '../services/api';
import logo from './img/favicon.svg' 

function Login() {
    const [data,setData]=useState([]);
    const [account,setAccount] = useState('')
    const [password,setPassword] = useState('')
    const [loginErrorMsg,setLoginErrorMsg] = useState(false)
    const [loginNoWifi,setLoginNoWifi] = useState(false)

    const history = useHistory();
    const location = useLocation()
    // console.log(location)
    const queryStringParse=queryString.parse(location.search);

    useEffect(()=>{
        async function checkLogin(){
            let result = await api.checkLoginApi()
            console.log(`使用者瀏覽器：${browserName} ${browserVersion}`);
            console.log('useragent',navigator.userAgent)

            console.log('login頁，checkLogin:',result)
            if(result&&result.account_id){
                if(queryStringParse&&queryStringParse.order_id){
                    history.push(`/?order_id=${queryStringParse.order_id}`);
                }else{
                    history.push(`/`);
                }
            }
        }
        checkLogin()
    },[])

    const inputAccountChange = (e) =>{
        setAccount(e.target.value)
    }

    const inputPasswordChange = (e) =>{
        setPassword(e.target.value)
    }

    const loginButton = async() =>{
        try{
            let result = await api.loginApi(account,password)
            console.log('result:',result)
            if(result&&result.account_id){
                console.log('success login!!!')
                // setDirectUrl({ redirect: "/counter" });
                if(queryStringParse&&queryStringParse.order_id){
                    history.push(`/?order_id=${queryStringParse.order_id}`);
                }else{
                    history.push(`/`);
                }
            }else{
                console.log(result)
                console.log(result.message)
                if(result&&result.message){
                    console.log('kkk')
                    if(result.message.indexOf('Network Error')>-1){
                        console.log('無網路')
                        setLoginNoWifi(true)
                    }
                }
                setLoginErrorMsg(true)
            }
        }catch(e){
            console.log('發生錯誤:',e)
            setLoginErrorMsg(true)

        }
    }


    return (

        <div className="body login">
           <section className="main">
                <div className="itemV width center">
                    <div className="img">
                        <img src={logo}/>
                    </div>
                    <span className="fXL white">QR code發票列印</span>
                </div>
                <div className="itemV width center">
                    <span className="fL white margin">請輸入帳號密碼</span>
                    <div className="input">
                        {/* <input type="text" className="fM" placeholder="" value=""/> */}
                        <input type="text" className="fM" onChange={inputAccountChange}/><br/>
                    </div>
                    <div className="input">
                        {/* <input type="password" className="fM" placeholder="" value=""/> */}
                        <input className="fM" type="password" onChange={inputPasswordChange}/>
                    </div>
                    <a onClick={loginButton} className="button">
                        <span className="fM">登入</span>
                    </a>
                </div>
            </section>
            <section style={loginErrorMsg?{display:'flex'}:{display:'none'}} id="popDelete" className="popup notice">
                <div className="popBody">
                    <a className="close"><i className="fas fa-times"></i></a>
                    <section className="popHeader">
                        <span className="fXL">登入失敗</span>
                    </section>
                    <section className="popMain">
                        {/* <span class="fL"></span> */}
                        {loginNoWifi?
                        <span className="fL">連線失敗 /<br/>請確認網路連線</span>
                            :<span className="fL">請檢查帳號密碼 /<br/>請至店家後台確認帳號密碼</span>}
                        {/* <button className="modalSubmit"  onClick={()=>{
                            setLoginErrorMsg(false)
                            setLoginNoWifi(false)
                        }} ></button> */}
                        <section className="popFooter">
                            <a onClick={()=>{
                            setLoginErrorMsg(false)
                            setLoginNoWifi(false)
                        }} className="button white"><span className="fM">確認</span></a>
                        </section>
                    </section>
                   
                </div>
            </section>
        </div>
    )
        
      /* <div>
          <section className="pin_login flex_center flex_wrap">
            
            <div className="flex_center flex_wrap" style={{"color":"white","fontSize":'30px'}} >
                
                <div className="flex_center">
                    <div className="" style={{  width: '80px',height: '80px',
                        borderRadius: '99px',
                        boxShadow: '0px 5px 10px rgba(55, 84, 170, 0.2)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                            
                        }}>
                        <img style={{  
                            width: '40px',
                            color: 'linear-gradient(107.8deg, #FF8008 0%, #FFC837 100%)'
                        }}></img>
                    </div>
                </div>
                <div className="" style={{"margin-top":"10px","color":"white","fontSize":'40px',width:'100%',display:'flex',justifyContent:'center'}} >
                    IDOO 愛點餐飲
                </div>
                <div className="flex_center" style={{width:'100%'}}>
                    櫃檯點餐系統
                </div>
            </div>

            <div className="box flex_center flex_wrap">
                <h2>請輸入帳號密碼</h2>
                <div className="pic_code flex_center">
                    <input onChange={inputAccountChange}/><br/>
                </div>
                <div className="pic_code flex_center">

                    <input type="password" onChange={inputPasswordChange}/>
                </div>

                
                <button onClick={loginButton} type="submit" className="login_btn">登入</button>
            </div>
            
            </section>
            <div  style={loginErrorMsg?{display:'flex'}:{display:'none'}}  id="modal">
                <div className="modalContainer">
                    <span className="modalClose" onClick={()=>{
                        setLoginErrorMsg(false)
                    }} >&times;</span>
                    <span className="modalTitle">登入失敗</span>

                    {loginNoWifi?
                        <span className="modalContent">連線失敗 /<br/>請確認網路連線</span>
                        :<span className="modalContent">請檢查帳號密碼 /<br/>請至店家後台確認帳號密碼</span>}
                    <button className="modalSubmit"  onClick={()=>{
                        setLoginErrorMsg(false)
                        setLoginNoWifi(false)
                    }} >
                        <span>確認</span>
                    </button>
                </div>
            </div>
        
    </div> */
    // );
  }
  
  export default Login;