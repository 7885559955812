import React, { useRef, useState ,useEffect } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
    Redirect,useHistory ,useParams,useLocation
} from "react-router-dom"; 
import queryString from 'query-string'


import api from  '../services/api';
import ScanPage from './ScanPage';
// import './index.css'


function OrderPage(props) {
    const [data, setData] = useState("");
    // const [scanStatus,setScanStatus] = useState(true)
    const [orderId,setOrderId] = useState('')
    const [nowOrder,setNowOrder]=useState({})
    // const [nowOrderDiscount,setNowOrderDiscount] = useState(0)
    // const [invoiceStatus,setInvoiceStatus] = useState({})
    const [account_id,setAccount_id] = useState('')
    const [user_info,setUser_info] = useState({})
    const [errorMsg,setErrorMsg] = useState([false,''])
    const [invoiceStatus,setInvoiceStatus] = useState('')
    const [statusReturn,setStatusReturn] = useState({
        "availableToIssue": true,
        "availableToPrint": true
    })
    const [invoiceInformation,setInvoiceInformation] = useState({})
    const [patchOrderMoney,setPatchOrderMoney] = useState('')
    const [patchOrderMoneyPositiveOrNagative,setPatchOrderMoneyPositiveOrNagative] = useState(true)

    const [isDDCInvoiceAccount,setIsDDCInvoiceAccount] = useState(false)
    const [showLoading,setShowLoading] = useState(false)
    const [showDoubleConfirmCancelOrder,setShowDoubleConfirmCancelOrder] = useState(false)
    const [showDoubleConfirmCancelInvoice,setShowDoubleConfirmCancelInvoice] = useState(false)
    const [showDoubleConfirmReprintInvoice,setShowDoubleConfirmReprintInvoice] = useState([false,''])

   
    const history = useHistory();
    const location = useLocation()
    // console.log(location)
    const queryStringParse=queryString.parse(location.search);
    // console.log('queryStringParse:::',queryStringParse)
    
    async function checkLogin(){
        let result = await api.checkLoginApi()
        let result2 = await api.enauth()

        console.log('enauth:',result2)

        if(result&&result.account_id){
            // history.push(`/counter/${result.account_id}`);
            console.log('QRCodePage Get Account:::',result)
            setAccount_id(result.account_id)
            setUser_info(result)
            return result.account_id
            // setAccount_id_from_session(result.account_id)
        }else{
            console.log('queryStringParse.order_id::',queryStringParse.order_id)
            if(queryStringParse.order_id){
                history.push(`/login?order_id=${queryStringParse.order_id}`)

            }else{
                history.push(`/login`)
            }
        }
    }

    useEffect(()=>{
        
        checkLogin()
        // 
        // setTimeout(function(){
        //     setData(JSON.stringify({
        //         "order_id":"0000015106"
        //     }))
        //     setInvoiceStatus({
        //         "availableToIssue": false,
        //         "availableToPrint": true
        //     })
        // },10)
        // setData({
        //     "order_id":"0000015106"
        // })
    },[])

    useEffect(()=>{
        async function getOrder(){
            if(queryStringParse.order_id){
                await getOrderApi(queryStringParse.order_id)
            }
        }
        getOrder()
    },[])

    async function getOrderApi(order_id){
        try{
            setShowLoading(true)
            let account_id = await checkLogin()
            let nowOrderApi = await api.getOrder(order_id)
            console.log('newOrder:',nowOrderApi)
            console.log('account_id:',account_id)
            if(nowOrderApi.store&&nowOrderApi.store.id){
                if(nowOrderApi.store.id==account_id){
                    // console.log('nowOrder:::',{...nowOrder})
                    setNowOrder({...nowOrderApi})
                    await determineIsDDCInvoiceStore(account_id)
                    // 取得發票狀態
                    let invoiceInformationResult = await api.getInvoiceInformationApi(order_id)
                    if(invoiceInformationResult&&invoiceInformationResult.result&&invoiceInformationResult.result.length){
                        setInvoiceInformation(invoiceInformationResult.result[0])
                        console.log('invoiceInformation:',invoiceInformationResult.result[0])

                    }
                    // 判斷畫面狀態
                    await detechStatusOfThisOrderInvoiceApi(order_id)
                }else{
                    console.log(`此發票擁有商家id為${nowOrderApi.store.id},登入帳號為id為${account_id}，故此發票非此商家使用。`)
                    setErrorMsg([true,'此訂單編號不存在（請確認登入商家）'])
                }
            }
            setShowLoading(false)
        }catch(e){
            console.log('getOrderApi錯誤',e.message)
            setShowLoading(false)

        }

    }

    async function detechStatusOfThisOrderInvoiceApi(order_id){
        // 這邊打狀態api
        console.log('detechStatusOfThisOrderInvoiceApi:',order_id)
        let statusApi = await api.getInvoiceStatusApi(order_id)
        let statusResults = statusApi.results
        console.log('status::',statusResults)
        
    
        // 假設回傳
        setStatusReturn(statusResults)
    }


    useEffect(()=>{
        detechStatusOfThisOrderInvoice()
    },[statusReturn])

    function detechStatusOfThisOrderInvoice(){
        
        
        // let statusReturn = {
        //     availableToIssue: false,
        //     availableToPrint: true,
        //     availableToCancel: true
        // }

        if(statusReturn){
            if(statusReturn.availableToIssue&&!statusReturn.availableToPrint){
            // 狀態一：開立且列印（畫面顯示開立且列印發票，沒有作廢）
                setInvoiceStatus('issueAndPrint')
            }else if(!statusReturn.availableToIssue&&statusReturn.availableToPrint){
            // 狀態二：列印（畫面顯示列印發票，沒有作廢）
                setInvoiceStatus('print')
            }else if(!statusReturn.availableToIssue&&!statusReturn.availableToPrint&&statusReturn.availableToCancel){
            // 狀態三：報廢（畫面列印發票無法按，下方有作廢）
                setInvoiceStatus('cancel')
            }else{
                setInvoiceStatus('cannotIsuueAndPrintAndCancel')
                // console.log('availableToIssue和availableToPrint都為true,有bug')
                // 畫面顯示只有掃描
                // setInvoiceStatus('issueAndPrint')
            }
        }
    }

    async function logout(){
        try{
          let result = await api.logoutApi()
          if(result&&result.message=='ok'){
              // history.push(`/counter/${result.account_id}`);
              // console.log('login:::',result.account_id)
            //   setAccount_id_from_session(result.account_id)
            //   clearOrders()
            history.push(`/login`);
          }else{
              console.log('登出失敗')
              setErrorMsg([true,'登出失敗'])
          }
        }catch(e){
          console.log('登出失敗:',e.stack)
        }
      }

    // const QRCodePage() {

    // }

    const Scan = ()=>{
        history.push(`/scan`)
    }

    const cancelInvoice = async(order_id) => {
        try{
            setShowLoading(true)

            // 這打cancelApi如果成功後
            let cancelIssueApi = await api.cancelIssueApi(order_id)
            console.log('cancelIssueApi::',cancelIssueApi)
            // 重新打一次狀態api
            let statusApi = await api.getInvoiceStatusApi(order_id)
            let statusResults = statusApi.results
            console.log('status::',statusResults)
            setStatusReturn(statusResults)
            setErrorMsg([true,'作廢發票成功'])

            // 重打一次訂單和發票狀態
            await getOrderApi(queryStringParse.order_id)
            setShowLoading(false)

            // 假設成功應該會回傳
            // setStatusReturn({
            //     "availableToIssue": true,
            //     "availableToPrint": false
            // })
        }catch(e){
            console.log(e)
            setShowLoading(false)
            setErrorMsg([true,'作廢發票失敗'])
        }
        
    }

    const translateFromOrderToPostIsuueBody = (nowOrderInput) => {
        console.log('nowOrderInput:',nowOrderInput)

        let returPostBody = {
            // "buyerTaxId": "12345678",
            // "carrierType": "3J0002",
            // "carrierId": "/X99PBR6",
            "items": [
              {
                "id": `${nowOrderInput.orderId}`,
                "name": "餐點費用",
                "unitPrice": nowOrderInput.amount,
                "qty": 1,
                "taxRate": 0.05
                // "id": "product0001",
                // "name": "餐點費用",
                // "unitPrice": 20,
                // "qty": 1,
                // "taxRate": 0.05
              }
            ]
        }
        // 有統編
        if(nowOrderInput.user&&nowOrderInput.user.taxId){
            returPostBody.buyerTaxId = nowOrderInput.user.taxId
        }
        // 有載具
        if(nowOrderInput.user&&nowOrderInput.user.carrierId&&nowOrderInput.receipt&&nowOrderInput.receipt.carrierType){
            returPostBody.carrierId = nowOrderInput.user.carrierId
            if(nowOrderInput.receipt.carrierType=='mobile'){
                returPostBody.carrierType = "3J0002"
            }
        }

        return returPostBody

    }

    const issueAndPrintInvoice = async(order_id) => {

        try{
            setShowLoading(true)
            let postBody = translateFromOrderToPostIsuueBody(nowOrder)
            // 這打issueApi且printApi如果成功後
            let postIssueApi = await api.postIssueApi(order_id,postBody)
            console.log('postIssueApi::',postIssueApi)
            // 手機載具有的話，列印會報錯，所以將來要判斷有沒有手機載具，執行列印，先把await去掉
            let printInvoiceApi = api.printIssueApi(order_id)
            console.log('printInvoiceApi::',printInvoiceApi)
            // 重新打一次狀態api
            let statusApi = await api.getInvoiceStatusApi(order_id)
            let statusResults = statusApi.results
            console.log('status::',statusResults)
            setStatusReturn(statusResults)
            setErrorMsg([true,'開立且列印發票成功'])

            // 重打一次訂單和發票狀態
            await getOrderApi(queryStringParse.order_id)
            setShowLoading(false)

        }catch(e){
            console.log(e)
            setShowLoading(false)
            setErrorMsg([true,'開立且列印發票失敗'])

        }
        
        // 假設成功應該會回傳
        // setStatusReturn({
        //     "availableToIssue": false,
        //     "availableToPrint": false
        // })
    }

    const cancelAndIssueAndPrintInvoice = async(order_id) => {
        try{
            setShowLoading(true)

            let postBody = translateFromOrderToPostIsuueBody(nowOrder)
            // 這打issueApi且printApi如果成功後
            let cancelIssueApi = await api.cancelIssueApi(order_id)
            console.log('cancelIssueApi::',cancelIssueApi)
            let postIssueApi = await api.postIssueApi(order_id,postBody)
            console.log('postIssueApi::',postIssueApi)
            // 手機載具有的話，列印會報錯，所以將來要判斷有沒有手機載具，執行列印，先把await去掉
            let printInvoiceApi = api.printIssueApi(order_id)
            console.log('printInvoiceApi::',printInvoiceApi)
            // 重新打一次狀態api
            let statusApi = await api.getInvoiceStatusApi(order_id)
            let statusResults = statusApi.results
            console.log('status::',statusResults)
            setStatusReturn(statusResults)
            setErrorMsg([true,'重開發票成功'])

            // 重打一次訂單和發票狀態
            await getOrderApi(queryStringParse.order_id)
            setShowLoading(false)

        }catch(e){
            console.log(e)
            setShowLoading(false)

            setErrorMsg([true,'重開發票失敗'])

        }
    }

    const printInvoice = async(order_id) => {
        try{
            setShowLoading(true)

            // 這打printApi如果成功後
            let printInvoiceApi = await api.printIssueApi(order_id)
            console.log('printInvoiceApi::',printInvoiceApi)
            // 重新打一次狀態api
            let statusApi = await api.getInvoiceStatusApi(order_id)
            let statusResults = statusApi.results
            console.log('status::',statusResults)
            setStatusReturn(statusResults)
            
            // 假設成功應該會回傳
            // setStatusReturn({
            //     "availableToIssue": false,
            //     "availableToPrint": false
            // })
            setShowLoading(false)

        }catch(e){
            console.log(e)
            setShowLoading(false)

            setErrorMsg([true,'列印發票失敗'])
        }
    }

    const cancelOrder = async(order_id) => {
        try{
            await api.cancelOrder(order_id)
            await api.cancelIssueApi(order_id)
            setErrorMsg([true,'取消訂單成功'])
            // 重打一次訂單和發票狀態
            await getOrderApi(queryStringParse.order_id)
        }catch(e){
            console.log(e.message)
            console.log(e)
            setErrorMsg([true,'取消訂單失敗'])
        }
    }
    

    // 調帳
    const patchOrder = async(order_id) => {
        console.log('調帳：',order_id,patchOrderMoney,patchOrderMoneyPositiveOrNagative)
        console.log(Number(patchOrderMoney))
        if(!Number(patchOrderMoney)){
            setErrorMsg([true,'請輸入正確數字'])
        }else{
            try{
                setShowLoading(true)

                let returnBody = {
                    "amount": 0, // 欲調整之訂單金額
                    "comments": { // 關於此筆調整的相關紀錄
                    "storeComments": "",
                    "systemLogs": ""
                    }
                }
                if(patchOrderMoneyPositiveOrNagative){
                    returnBody.amount = nowOrder.amount+Number(patchOrderMoney)
                }else{
                    returnBody.amount = nowOrder.amount-Number(patchOrderMoney)
                }
                if(user_info&&user_info.username){
                    returnBody.comments.systemLogs = user_info.username
                }
                console.log('調帳內容:',returnBody)
                
                // 打調帳api
                await api.patchOrder(order_id,returnBody)

               

                setErrorMsg([true,'調帳成功'])
                setPatchOrderMoneyPositiveOrNagative(true)
                setPatchOrderMoney('')

                 // 重新抓取order
                //  let nowOrderResult = await api.getOrder(order_id)
                //  if(nowOrderResult.store&&nowOrderResult.store.id){
                //      if(nowOrderResult.store.id==account_id){
                //         console.log('重新改變nowOrder:',{...nowOrder})
                //         setNowOrder({...nowOrder})
                //      }
                //  }
                setNowOrder({...nowOrder,amount:returnBody.amount})
                setShowLoading(false)

            }catch(e){
                setErrorMsg([true,'調帳失敗'])
                setShowLoading(false)

            }
        }
        

    }

    const inputPatchMoney = (e) =>{
        setPatchOrderMoney(e.target.value)
    }
    
    const determineIsDDCInvoiceStore = async(account_id)=>{
        let isDDCInvoiceStore = await api.isDDCInvoiceStoreApi(account_id)
        console.log('isDDCInvoiceStoreResult:',isDDCInvoiceStore)
        if(isDDCInvoiceStore&&isDDCInvoiceStore.result&&isDDCInvoiceStore.result.length){
            if(isDDCInvoiceStore.result[0].is_ddc_invoice==1){
                setIsDDCInvoiceAccount(true)
            }else{
                setIsDDCInvoiceAccount(false)
            }
        }
    }


    const changeTimeToInvoiceDateInterval = (thisTimestamp) =>{
        // console.log('changeTimeToInvoiceDateInterval:')
        // console.log(new Date(thisTimestamp).getFullYear())
        // console.log(new Date(thisTimestamp).getMonth())
        // console.log(new Date(thisTimestamp).toLocaleDateString())
        let yearTaiwan = new Date(thisTimestamp).getFullYear()-1911
        let month1 = 0
        let month2 = 0
        if(new Date(thisTimestamp).getMonth()%2==1){
            month1 = new Date(thisTimestamp).getMonth()
            month2 = new Date(thisTimestamp).getMonth()+1
        }else{
            month1 = new Date(thisTimestamp).getMonth()+1
            month2 = new Date(thisTimestamp).getMonth()+2
        }
        if(month1==12){
            month2 = 1
        }
        if(month1<10){
            month1 = '0'+month1
        }else{
            month1 = month1.toString()
        }
        if(month2<10){
            month2 = '0'+month2
        }else{
            month2 = month2.toString()
        }

        return {yearTaiwan,month1,month2}
    }


    return (
        
    <div className="body index">

{/* <!-- Spinner --> */}
         <div className="spinner" style={showLoading?{display:'flex'}:{display:'none'}}><div><div></div></div></div> 

        <a className="icon" onClick={logout}>
            <div className="icon">
                <i className="fas fa-sign-out-alt gray"></i>
            </div>
            <span style={{fontSize:'14px'}} className="fL gray">登出</span>
        </a>
        <section className="header">
            {/* <span className="fL primary">訂單資料</span> */}
            
            <div className="itemV center">
                <span className="fL primary">取餐編號</span>
                {/* <span class="fL primary bold">1</span> */}
                <span style={nowOrder&&nowOrder.displayId?{display:'flex'}:{display:'none'}} className="fL primary bold">{nowOrder.displayId}</span>

            </div>
            <div className="itemV center">
                <span className="fL primary">訂單編號</span>
                {/* <span class="fL primary bold">123456</span> */}
                <span style={nowOrder&&nowOrder.orderId?{display:'flex'}:{display:'none'}} className="fL primary bold">{nowOrder.orderId}</span>
            </div>
        </section>
    

        <section className="main">
            {/* <div className="itemH width between">
                <span className="fL">取餐編號</span>
                <span style={nowOrder&&nowOrder.displayId?{display:'flex'}:{display:'none'}} className="fM bold">{nowOrder.displayId}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">訂單編號</span>
                <span style={nowOrder&&nowOrder.orderId?{display:'flex'}:{display:'none'}} className="fM bold">{nowOrder.orderId}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">訂單類型</span>
                <span style={nowOrder&&nowOrder.mealType?{display:'flex'}:{display:'none'}} className="fM bold">{nowOrder.mealType=='eat_in'?'內用':nowOrder.mealType=='to_go'?'自取':'外送'}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">訂單時間</span>
                <span style={nowOrder&&nowOrder.createdTs?{display:'flex'}:{display:'none'}} className="fM bold">{new Date(nowOrder.createdTs*1000).toLocaleString()}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">取餐時間</span>
                <span style={nowOrder&&nowOrder.createdTs?{display:'flex'}:{display:'none'}} className="fM bold">{new Date(nowOrder.pickupTs*1000).toLocaleString()}</span>
            </div> */}
            <div className="receipt itemV center width padding margin">
                    <span className="fXL margin">{nowOrder.store?nowOrder.store.name:''}</span>
                    <span className="fXL margin">電子發票證明聯</span>
                    <div  style={invoiceStatus=='print'||invoiceStatus=='cancel'?{display:'flex'}:{display:'none'}} className="itemH margin">
                        {/* <span class="fXXL sans">{new Date(nowOrder.createdTs)}</span> */}
                        {invoiceInformation&&invoiceInformation.created_at?
                        <div>
                            <span className="fXXL">{changeTimeToInvoiceDateInterval(invoiceInformation.created_at).yearTaiwan}</span>
                            <span className="fXXL">年</span>
                            <span className="fXXL sans">{changeTimeToInvoiceDateInterval(invoiceInformation.created_at).month1}-{changeTimeToInvoiceDateInterval(nowOrder.createdTs).month2}</span>
                            <span className="fXXL">月</span>
                        </div>
                        :''
                        }   
                        
                    </div>
                    <span style={invoiceStatus=='print'||invoiceStatus=='cancel'?{display:'flex'}:{display:'none'}}  className="fXXL margin sans">
                        {invoiceInformation&&invoiceInformation.invoice_number?invoiceInformation.invoice_number:''}
                    </span>
                    <div className="itemH width between margin">
                        {/* <span class="fL sans">2020-10-09 18:27:13</span> */}
                        <span style={nowOrder&&nowOrder.createdTs?{display:'flex'}:{display:'none'}} className="fL sans">
                            {invoiceInformation&&invoiceInformation.created_at?
                            new Date(invoiceInformation.created_at).toLocaleString():
                            new Date(nowOrder.createdTs*1000).toLocaleString()}
                        </span>
                        <span style={nowOrder&&nowOrder.amount?{display:'flex'}:{display:'none'}}  className="fL">總計：<span className="sans">{nowOrder.amount}</span></span>

                        {/* <span class="fL">總計：<span class="sans">987</span></span> */}
                    </div>
                    {/* <div class="itemH width between margin">
                        <span class="fL">賣方：<span class="sans">87654321</span></span>
                        <span class="fL">買方：<span class="sans">87654321</span></span>
                    </div> */}
                </div>
                {/* <span class="fM margin">已入載具：/87654321</span> */}
         
                <span style={invoiceStatus=='print'||invoiceStatus=='cancel'?{display:'flex'}:{display:'none'}}  className="fM margin">
                    {/* 載具：/87654321 */}
                    <span style={nowOrder&&nowOrder.user&&nowOrder.user.carrierId?{display:'flex'}:{display:'none'}}  className="fM bold">載具：{nowOrder.user?nowOrder.user.carrierId:''}</span>

                </span>
                <span style={invoiceStatus=='print'||invoiceStatus=='cancel'?{display:'flex'}:{display:'none'}}  className="fM margin">
                    {/* 統編：/87654321 */}
                    <span style={nowOrder&&nowOrder.user&&nowOrder.user.taxId?{display:'flex'}:{display:'none'}}  className="fM bold">統編：{nowOrder.user?nowOrder.user.taxId:''}</span>

                </span>
                <span className="fM margin"
                //  style={nowOrder&&nowOrder.currenState?{display:'flex'}:{display:'none'}} 
                >
                    訂單狀態：{nowOrder.currentState=='new'?'新訂單但尚未接單':
                    nowOrder.currentState=='accepted'?'已接單':
                    nowOrder.currentState=='rejected'?'拒絕接單':
                    nowOrder.currentState=='cancelled'?'已取消訂單':nowOrder.currentState
                }
                    
                </span>
                <span className="fM margin">
                    發票狀態：{
                        !nowOrder||!invoiceStatus?'':
                        isDDCInvoiceAccount==false?'此商店未開通發票':
                        invoiceStatus=='issueAndPrint'?'可開立發票':
                        invoiceStatus=='print'?'尚未待列印發票':
                        invoiceStatus=='cancel'?'已開立發票':
                        invoiceStatus=='cannotIsuueAndPrintAndCancel'?'無法開立、列印和報廢':'無發票'
                    }
                </span>
                <div className="input">
                    <a className="switch itemH">
                        <div onClick={()=>setPatchOrderMoneyPositiveOrNagative(true)} className={patchOrderMoneyPositiveOrNagative?"icon":"icon off"}><i className="fas fa-plus"></i></div>
                        <div onClick={()=>setPatchOrderMoneyPositiveOrNagative(false)} className={patchOrderMoneyPositiveOrNagative?"icon off":"icon"}><i className="fas fa-minus"></i></div>
                    </a>
                    <span className="fL margin">$</span>
                    <input onChange={inputPatchMoney} type="text" className="fL" placeholder="店家調帳" value={patchOrderMoney}/>
                    <a onClick={()=>patchOrder(nowOrder.orderId)} className="button"><span className="fM">確認調帳</span></a>
                </div>

        </section>

        <section className="footer">
            {/* 先判斷是不是ddc_invocie廠商，不是的話連重印發票按鈕都沒有 */}
            {/* 如果是訂單已取消的狀態，不能按重印發票按鈕 */}
            {/* 如果都不是上述，判斷狀態是可以開立發票，就顯示開立且列印發票，執行開立、列印發票 */}
            {/* 判斷狀態是可以列印發票，就顯示重印發票，執行列印發票 */}
            {/* 判斷狀態是可以取消發票，就顯示重印印發票，執行報廢、開立、且列印發票 */}
            {!isDDCInvoiceAccount?'':
            nowOrder&&nowOrder.currentState&&nowOrder.currentState=='cancelled'?
            <a className='button submit disabled'  id="buttonLarge">
                <div className="icon"><i className="fas fa-receipt"></i></div>
                <span className="fL">重開發票</span>
            </a>:
            invoiceStatus=='issueAndPrint'?
            <a onClick={()=>setShowDoubleConfirmReprintInvoice([true,'issueAndPrintInvoice'])}  id="buttonLarge" className="button submit">
                <div className="icon"><i className="fas fa-receipt"></i></div>
                <span className="fL">開立且列印發票</span>
            </a>:
            invoiceStatus=='print'?
            <a onClick={()=>setShowDoubleConfirmReprintInvoice([true,'printInvoice'])}  id="buttonLarge" className="button submit">
                <div className="icon"><i className="fas fa-receipt"></i></div>
                <span className="fL">重開發票</span>
            </a>:
            invoiceStatus=='cancel'?
            <a onClick={()=>setShowDoubleConfirmReprintInvoice([true,'cancelAndIssueAndPrintInvoice'])}  id="buttonLarge" className="button submit">
                <div className="icon"><i className="fas fa-receipt"></i></div>
                <span className="fL">重開發票</span>
            </a>:
            <a className='button submit disabled'  id="buttonLarge">
                <div className="icon"><i className="fas fa-receipt"></i></div>
                <span className="fL">重開發票</span>
            </a>
            }
            {nowOrder&&nowOrder.platform&&((nowOrder.platform=='ubereats')||(nowOrder.platform=='foodpanda'))?
            <a className={(nowOrder&&nowOrder.currentState&&nowOrder.currentState=='cancelled')?"button delete disabled":"button delete"} onClick={()=>setShowDoubleConfirmCancelInvoice(true)} id="buttonCancel">
                <div className="icon"><i className="far fa-times-circle"></i></div>
                <span className="fL">作廢發票</span>
            </a>:
            <a className={(nowOrder&&nowOrder.currentState&&nowOrder.currentState=='cancelled')?"button delete disabled":"button delete"} onClick={()=>setShowDoubleConfirmCancelOrder(true)} id="buttonCancel">
                <div className="icon"><i className="far fa-times-circle"></i></div>
                <span className="fL">取消訂單</span>
            </a>
            }
            
        </section>

        {/* <section className="footer">
            <div className="itemH full" style={invoiceStatus=='cancel'?{backgroundColor:'#D3D3D3'}:{}}> 
                <a className="button white" onClick={Scan}>
                    <div className="icon black">
                        <i className="fas fa-qrcode margin"></i>
                    </div>
                    <span className="fL">掃描</span>
                </a>
                {invoiceStatus=='issueAndPrint'?
                <a onClick={()=>issueAndPrintInvoice(nowOrder.orderId)} style={nowOrder&&nowOrder.orderId?{display:'flex'}:{display:'none'}} className="button submit">
                    <div className="icon white">
                        <i className="fas fa-receipt margin"></i>
                    </div>
                    <span className="fL">開立且列印發票</span>
                </a>
                :invoiceStatus=='print'?
                <a onClick={()=>printInvoice(nowOrder.orderId)} style={nowOrder&&nowOrder.orderId?{display:'flex'}:{display:'none'}} className="button submit">                        
                    <div className="icon white">
                        <i className="fas fa-receipt margin"></i>
                    </div>
                    <span className="fL">列印發票</span>
                </a>
                :invoiceStatus=='cancel'?
                <a style={nowOrder&&nowOrder.orderId?{display:'flex',backgroundColor:'#D3D3D3'}:{display:'none'}} className="button submit">                        
                    <div className="icon white">
                        <i className="fas fa-receipt margin"></i>
                    </div>
                    <span className="fL">發票已列印</span>
                </a>:''}
            </div>
            
            <div className="itemH width between">
                <span className="fL">餐費</span>
                <span style={nowOrder&&nowOrder.amount?{display:'flex'}:{display:'none'}}  className="fM bold"><span className="fXS">$</span>{nowOrder.amount}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">統編</span>
                <span style={nowOrder&&nowOrder.user&&nowOrder.user.taxId?{display:'flex'}:{display:'none'}}  className="fM bold">{nowOrder.user?nowOrder.user.taxId:''}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">載具</span>
                <span style={nowOrder&&nowOrder.user&&nowOrder.user.carrierId?{display:'flex'}:{display:'none'}}  className="fM bold">{nowOrder.user?nowOrder.user.carrierId:''}</span>
            </div>
            <div className="itemH width" style={nowOrder&&nowOrder.orderId&&invoiceStatus=='cancel'?{display:'flex'}:{display:'none'}} >
                <a onClick={()=>cancelInvoice(nowOrder.orderId)} id="buttonDelete" className="button white">
                    <div className="icon">
                        <i className="far fa-times-circle error"></i>
                    </div>
                    <span className="fL under error">發票作廢</span>
                </a>
            </div>
        </section> */}

        {/* message */}
        <section style={errorMsg[0]?{display:'flex'}:{display:'none'}} id="popDelete" className="popup notice">
            <div className="popBody">
                <a onClick={()=>setErrorMsg([false,''])} className="close"><i className="fas fa-times"></i></a>
                <section className="popHeader">
                    <span className="fXL">通知</span>
                </section>
                <section className="popMain">

                    <span className="fL"> <br/>{errorMsg[1]}</span>
                    
                    <section className="popFooter">
                        <a onClick={()=>{
                            setErrorMsg([false,''])
                        }} className="button white"><span className="fM">確認</span>
                        </a>
                        {/* <a class="button cancel"><span class="fS">放棄</span></a> */}
                        {/* <a class="button delete"><span class="fS">取消訂單</span></a> */}
                    </section>
                </section>
                
            </div>
        </section>

        {/* double confirm 取消訂單 */}
        <section style={showDoubleConfirmCancelOrder?{display:'flex'}:{display:'none'}} id="popDelete" className="popup notice">
            <div className="popBody">
                <a onClick={()=>setShowDoubleConfirmCancelOrder(false)}className="close"><i className="fas fa-times"></i></a>
                <section className="popHeader">
                    <span className="fXL">確認通知</span>
                </section>
                <section className="popMain">

                    <span className="fL"> <br/>是否確認取消訂單</span>
                    
                    <section className="popFooter">
                        <a onClick={()=>{
                            setShowDoubleConfirmCancelOrder(false)
                        }} className="button cancel"><span class="fS">放棄</span></a>
                        <a onClick={()=>{
                            // 關閉視窗
                            setShowDoubleConfirmCancelOrder(false)
                            // 取消訂單
                            cancelOrder(nowOrder.orderId)
                        }}  className="button delete"><span class="fS">確認</span></a>
                    </section>
                </section>
            </div>
        </section>

        {/* double confirm 作廢發票 */}
        <section style={showDoubleConfirmCancelInvoice?{display:'flex'}:{display:'none'}} id="popDelete" className="popup notice">
            <div className="popBody">
                <a onClick={()=>setShowDoubleConfirmCancelInvoice(false)}className="close"><i className="fas fa-times"></i></a>
                <section className="popHeader">
                    <span className="fXL">確認通知</span>
                </section>
                <section className="popMain">

                    <span className="fL"> <br/>是否確認取消訂單</span>
                    
                    <section className="popFooter">
                        <a onClick={()=>{
                            setShowDoubleConfirmCancelInvoice(false)
                        }} className="button cancel"><span class="fS">放棄</span></a>
                        <a onClick={()=>{
                            // 關閉視窗
                            setShowDoubleConfirmCancelInvoice(false)
                            // 取消訂單
                            cancelInvoice(nowOrder.orderId)
                        }}  className="button delete"><span class="fS">確認</span></a>
                    </section>
                </section>
            </div>
        </section>

        {/* double confirm 重印發票 */}
        <section style={showDoubleConfirmReprintInvoice[0]?{display:'flex'}:{display:'none'}} id="popDelete" className="popup notice">
            <div className="popBody">
                <a onClick={()=>{
                    setShowDoubleConfirmReprintInvoice([false,''])
                }} className="close"><i className="fas fa-times"></i></a>
                <section className="popHeader">
                    <span className="fXL">確認通知</span>
                </section>
                <section className="popMain">

                    <span className="fL"> <br/>是否確認重開發票</span>
                    
                    <section className="popFooter">
                        <a onClick={()=>{
                            setShowDoubleConfirmReprintInvoice([false,''])
                        }} className="button cancel"><span class="fS">放棄</span></a>
                        <a onClick={()=>{
                            // 取消訂單
                            if(showDoubleConfirmReprintInvoice[1]=='issueAndPrintInvoice'){
                                issueAndPrintInvoice(nowOrder.orderId)
                            }else if(showDoubleConfirmReprintInvoice[1]=='printInvoice'){
                                printInvoice(nowOrder.orderId)
                            }else if(showDoubleConfirmReprintInvoice[1]=='cancelAndIssueAndPrintInvoice'){
                                cancelAndIssueAndPrintInvoice(nowOrder.orderId)
                            }
                            // 關閉視窗
                            setShowDoubleConfirmReprintInvoice([false,''])
                        }}  className="button delete"><span class="fS">確認</span></a>
                    </section>
                </section>
            </div>
        </section>


    
      </div>
    );
  
};

export default OrderPage;