import React, { useRef, useState ,useEffect } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
    Redirect,useHistory ,useParams,useLocation
} from "react-router-dom"; 
import queryString from 'query-string'


import api from  '../services/api';
import ScanPage from './ScanPage';
// import './index.css'


function QRCodePage(props) {
    const [data, setData] = useState("");
    // const [scanStatus,setScanStatus] = useState(true)
    const [orderId,setOrderId] = useState('')
    const [nowOrder,setNowOrder]=useState({})
    // const [nowOrderDiscount,setNowOrderDiscount] = useState(0)
    // const [invoiceStatus,setInvoiceStatus] = useState({})
    const [account_id,setAccount_id] = useState('')
    const [user_info,setUser_info] = useState({})
    const [errorMsg,setErrorMsg] = useState([false,''])
    const [invoiceStatus,setInvoiceStatus] = useState('')
    const [statusReturn,setStatusReturn] = useState({
        "availableToIssue": true,
        "availableToPrint": true
    })
   
    const history = useHistory();
    const location = useLocation()
    // console.log(location)
    const queryStringParse=queryString.parse(location.search);
    console.log('queryStringParse:::',queryStringParse)
    
    async function checkLogin(){
        let result = await api.checkLoginApi()
        let result2 = await api.enauth()

        console.log('enauth:::',result2)

        if(result&&result.account_id){
            // history.push(`/counter/${result.account_id}`);
            console.log('QRCodePage Get Account:::',result)
            setAccount_id(result.account_id)
            setUser_info(result)
            return result.account_id
            // setAccount_id_from_session(result.account_id)
        }else{
            if(queryStringParse.order_id){
                history.push(`/login?order_id=${queryStringParse.order_id}`)

            }else{
                history.push(`/login`)
            }
        }
    }

    useEffect(()=>{
        
        checkLogin()
        // 
        // setTimeout(function(){
        //     setData(JSON.stringify({
        //         "order_id":"0000015106"
        //     }))
        //     setInvoiceStatus({
        //         "availableToIssue": false,
        //         "availableToPrint": true
        //     })
        // },10)
        // setData({
        //     "order_id":"0000015106"
        // })
    },[])

    useEffect(()=>{
        async function getOrder(){
            if(queryStringParse.order_id){
                await getOrderApi(queryStringParse.order_id)
            }
        }
        getOrder()
    },[])

    async function getOrderApi(order_id){
        let account_id = await checkLogin()
        let nowOrder = await api.getOrder(order_id)
        console.log('newOrder:',nowOrder)
        console.log('account_id:',account_id)
        if(nowOrder.store&&nowOrder.store.id){
            if(nowOrder.store.id==account_id){
                setNowOrder({...nowOrder})
                // 判斷畫面狀態
                await detechStatusOfThisOrderInvoiceApi(order_id)
            }else{
                console.log(`此發票擁有商家id為${nowOrder.store.id},登入帳號為id為${account_id}，故此發票非此商家使用。`)
                setErrorMsg([true,'此發票非此商家使用。'])
            }
        }

    }

    async function detechStatusOfThisOrderInvoiceApi(order_id){
        // 這邊打狀態api
        console.log('detechStatusOfThisOrderInvoiceApi:',order_id)
        let statusApi = await api.getInvoiceStatusApi(order_id)
        let statusResults = statusApi.results
        console.log('status::',statusResults)
        
    
        // 假設回傳
        setStatusReturn(statusResults)
    }


    useEffect(()=>{
        detechStatusOfThisOrderInvoice()
    },[statusReturn])

    function detechStatusOfThisOrderInvoice(){
        
        
        // let statusReturn = {
        //     availableToIssue: false,
        //     availableToPrint: true,
        //     availableToCancel: true
        // }

        if(statusReturn){
            if(statusReturn.availableToIssue&&!statusReturn.availableToPrint){
            // 狀態一：開立且列印（畫面顯示開立且列印發票，沒有作廢）
                setInvoiceStatus('issueAndPrint')
            }else if(!statusReturn.availableToIssue&&statusReturn.availableToPrint){
            // 狀態二：列印（畫面顯示列印發票，沒有作廢）
                setInvoiceStatus('print')
            }else if(!statusReturn.availableToIssue&&!statusReturn.availableToPrint){
            // 狀態三：報廢（畫面列印發票無法按，下方有作廢）
                setInvoiceStatus('cancel')
            }else{
                console.log('availableToIssue和availableToPrint都為true,有bug')
                // 畫面顯示只有掃描
                // setInvoiceStatus('issueAndPrint')
            }
        }
    }

    async function logout(){
        try{
          let result = await api.logoutApi()
          if(result&&result.message=='ok'){
              // history.push(`/counter/${result.account_id}`);
              // console.log('login:::',result.account_id)
            //   setAccount_id_from_session(result.account_id)
            //   clearOrders()
              history.push(`/login`);
          }else{
              console.log('登出失敗')
              setErrorMsg([true,'登出失敗'])
          }
        }catch(e){
          console.log('登出失敗:',e.stack)
        }
      }

    // const QRCodePage() {

    // }

    const Scan = ()=>{
        history.push(`/scan`)
    }

    const cancelInvoice = async(order_id) => {
        // 這打cancelApi如果成功後
        let cancelIssueApi = await api.cancelIssueApi(order_id)
        console.log('cancelIssueApi::',cancelIssueApi)
        // 重新打一次狀態api
        let statusApi = await api.getInvoiceStatusApi(order_id)
        let statusResults = statusApi.results
        console.log('status::',statusResults)
        setStatusReturn(statusResults)

        // 假設成功應該會回傳
        // setStatusReturn({
        //     "availableToIssue": true,
        //     "availableToPrint": false
        // })
        
    }

    const translateFromOrderToPostIsuueBody = (nowOrderInput) => {
        console.log('nowOrderInput:',nowOrderInput)

        let returPostBody = {
            // "buyerTaxId": "12345678",
            // "carrierType": "3J0002",
            // "carrierId": "/X99PBR6",
            "items": [
              {
                "id": `${nowOrderInput.orderId}`,
                "name": "餐點費用",
                "unitPrice": nowOrderInput.amount,
                "qty": 1,
                "taxRate": 0.05
                // "id": "product0001",
                // "name": "餐點費用",
                // "unitPrice": 20,
                // "qty": 1,
                // "taxRate": 0.05
              }
            ]
        }
        // 有統編
        if(nowOrderInput.user&&nowOrderInput.user.taxId){
            returPostBody.buyerTaxId = nowOrderInput.user.taxId
        }
        // 有載具
        if(nowOrderInput.user&&nowOrderInput.user.carrierId&&nowOrderInput.receipt&&nowOrderInput.receipt.carrierType){
            returPostBody.carrierId = nowOrderInput.user.carrierId
            if(nowOrderInput.receipt.carrierType=='mobile'){
              returPostBody.carrierType = "3J0002"
            }
        }

        return returPostBody

    }

    const issueAndPrintInvoice = async(order_id) => {

        try{
            let postBody = translateFromOrderToPostIsuueBody(nowOrder)
            // 這打issueApi且printApi如果成功後
            let postIssueApi = await api.postIssueApi(order_id,postBody)
            console.log('postIssueApi::',postIssueApi)
            let printInvoiceApi = await api.printIssueApi(order_id)
            console.log('printInvoiceApi::',printInvoiceApi)
            // 重新打一次狀態api
            let statusApi = await api.getInvoiceStatusApi(order_id)
            let statusResults = statusApi.results
            console.log('status::',statusResults)
            setStatusReturn(statusResults)
        }catch(e){
            console.log(e)
        }
        
        // 假設成功應該會回傳
        // setStatusReturn({
        //     "availableToIssue": false,
        //     "availableToPrint": false
        // })
    }

    const printInvoice = async(order_id) => {
        // 這打printApi如果成功後
        let printInvoiceApi = await api.printIssueApi(order_id)
        console.log('printInvoiceApi::',printInvoiceApi)
        // 重新打一次狀態api
        let statusApi = await api.getInvoiceStatusApi(order_id)
        let statusResults = statusApi.results
        console.log('status::',statusResults)
        setStatusReturn(statusResults)
        
        // 假設成功應該會回傳
        // setStatusReturn({
        //     "availableToIssue": false,
        //     "availableToPrint": false
        // })
    }

    // useEffect(()=>{
       

    //     try{
    //         if(data){
    //             console.log('data:',data)
    //             let order_info = JSON.parse(data)
    //             console.log('order_info:',order_info)
    //             let order_id = order_info.order_id
    //             console.log('order_id:',order_id)
    //             setOrderId(order_id)
    //             getOrderApi(order_id)
    //         }

    //     }catch(e){
    //         console.log('不是json:',e)
    //     }
    // },[data])


    return (
        
    <div className="body index">

        <section className="header">
            <span className="fL primary">訂單資料</span>
            <a className="icon" onClick={logout}>
                <div className="icon">
                    <i className="fas fa-sign-out-alt gray"></i>
                </div>
                <span className="fL gray">登出</span>
            </a>
        </section>

        <section className="main">
            <div className="itemH width between">
                <span className="fL">取餐編號</span>
                <span style={nowOrder&&nowOrder.displayId?{display:'flex'}:{display:'none'}} className="fM bold">{nowOrder.displayId}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">訂單編號</span>
                <span style={nowOrder&&nowOrder.orderId?{display:'flex'}:{display:'none'}} className="fM bold">{nowOrder.orderId}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">訂單類型</span>
                <span style={nowOrder&&nowOrder.mealType?{display:'flex'}:{display:'none'}} className="fM bold">{nowOrder.mealType=='eat_in'?'內用':nowOrder.mealType=='to_go'?'自取':'外送'}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">訂單時間</span>
                <span style={nowOrder&&nowOrder.createdTs?{display:'flex'}:{display:'none'}} className="fM bold">{new Date(nowOrder.createdTs*1000).toLocaleString()}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">取餐時間</span>
                <span style={nowOrder&&nowOrder.createdTs?{display:'flex'}:{display:'none'}} className="fM bold">{new Date(nowOrder.pickupTs*1000).toLocaleString()}</span>
            </div>
        </section>

        <section className="footer">
                {/* <!-- Button --><!-- className: disabled --> */}
            <div className="itemH full" style={invoiceStatus=='cancel'?{backgroundColor:'#D3D3D3'}:{}}> 
                <a className="button white" onClick={Scan}>
                    <div className="icon black">
                        <i className="fas fa-qrcode margin"></i>
                    </div>
                    <span className="fL">掃描</span>
                </a>
                {/* comment掉下面就只剩掃描會佈滿 */}
                {invoiceStatus=='issueAndPrint'?
                <a onClick={()=>issueAndPrintInvoice(nowOrder.orderId)} style={nowOrder&&nowOrder.orderId?{display:'flex'}:{display:'none'}} className="button submit">
                    <div className="icon white">
                        <i className="fas fa-receipt margin"></i>
                    </div>
                    <span className="fL">開立且列印發票</span>
                </a>
                :invoiceStatus=='print'?
                <a onClick={()=>printInvoice(nowOrder.orderId)} style={nowOrder&&nowOrder.orderId?{display:'flex'}:{display:'none'}} className="button submit">                        
                    <div className="icon white">
                        <i className="fas fa-receipt margin"></i>
                    </div>
                    <span className="fL">列印發票</span>
                </a>
                :invoiceStatus=='cancel'?
                <a style={nowOrder&&nowOrder.orderId?{display:'flex',backgroundColor:'#D3D3D3'}:{display:'none'}} className="button submit">                        
                    <div className="icon white">
                        <i className="fas fa-receipt margin"></i>
                    </div>
                    <span className="fL">發票已列印</span>
                </a>:''}
            </div>
            
            <div className="itemH width between">
                <span className="fL">餐費</span>
                <span style={nowOrder&&nowOrder.amount?{display:'flex'}:{display:'none'}}  className="fM bold"><span className="fXS">$</span>{nowOrder.amount}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">統編</span>
                <span style={nowOrder&&nowOrder.user&&nowOrder.user.taxId?{display:'flex'}:{display:'none'}}  className="fM bold">{nowOrder.user?nowOrder.user.taxId:''}</span>
            </div>
            <div className="itemH width between">
                <span className="fL">載具</span>
                <span style={nowOrder&&nowOrder.user&&nowOrder.user.carrierId?{display:'flex'}:{display:'none'}}  className="fM bold">{nowOrder.user?nowOrder.user.carrierId:''}</span>
            </div>
            <div className="itemH width" style={nowOrder&&nowOrder.orderId&&invoiceStatus=='cancel'?{display:'flex'}:{display:'none'}} >
                <a onClick={()=>cancelInvoice(nowOrder.orderId)} id="buttonDelete" className="button white">
                    <div className="icon">
                        <i className="far fa-times-circle error"></i>
                    </div>
                    <span className="fL under error">發票作廢</span>
                </a>
            </div>
        </section>

        <section style={errorMsg[0]?{display:'flex'}:{display:'none'}} id="popDelete" className="popup notice">
                <div className="popBody">
                    <a className="close"><i className="fas fa-times"></i></a>
                    <section className="popHeader">
                        <span className="fXL">失敗通知</span>
                    </section>
                    <section className="popMain">

                        <span className="fL"> <br/>{errorMsg[1]}</span>
                       
                        <section className="popFooter">
                            <a onClick={()=>{
                            setErrorMsg([false,''])
                        }} className="button white"><span className="fM">確認</span></a>
                        </section>
                    </section>
                   
                </div>
            </section>

        {/* {scanStatus?<BarcodeScannerComponent
          width={'100%'}
          height={500}
          onUpdate={(err, result) => {
            if (result){
                setData(result.text);
                
                setScanStatus(false)
            }
            else setData("Not Found");
          }}
        />:''}
        <p>掃描資料：{data}</p>
        {orderId?
        <div>
            {nowOrder?
                <div>
                    <p>餐費: {nowOrder.amount}</p>
                    {nowOrder.buyerTaxId?<p>統編: {nowOrder.buyerTaxId}</p>:''}
                    {nowOrder.carrierId?<p>載具: {nowOrder.carrierId}</p>:''}
                    <p>取餐編號: {nowOrder.displayId}</p>
                    <p>訂單編號: {nowOrder.orderId}</p>
                    <p>訂單類型: {nowOrder.mealType=='eat_in'?'內用':nowOrder.eat_in=='to_go'?'外帶':'外送'}</p>
                    <p>訂單時間: {new Date(nowOrder.createdTs*1000).toLocaleString()}</p>
                    <p>取餐時間: {new Date(nowOrder.pickupTs*1000).toLocaleString()}</p>
                    <button onClick={()=>setScanStatus(true)}>掃描</button>

                    {
                    !invoiceStatus?
                        <button disabled="disabled">列印發票</button>:
                        invoiceStatus.availableToIssue&&invoiceStatus.availableToPrint?
                            <button>列印(開立)發票</button>
                            :!invoiceStatus.availableToIssue&&invoiceStatus.availableToPrint?
                                <button>列印發票</button>
                                :!invoiceStatus.availableToIssue&&invoiceStatus.availableToPrint?
                                    <button>列印發票</button>

                                    :<button disabled="disabled">列印發票</button>
                    }
                </div>
                :
                <div>
                    <button onClick={()=>setScanStatus(true)}>掃描</button>
                </div>
            }
        </div>
        :''} */}
      </div>
    );
  
};

export default QRCodePage;