import logo from './logo.svg';
import './App.css';
// import WebcamVideo from './components/WebcamVideo'
// import QRCodeScanner from './components/QRCodeScanner'
// import QS from './components/QS'
// import QrReader from 'react-qr-scanner'
import React, { useRef, useState ,useEffect } from 'react';
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import QRCodePage from './pages/QRCodePage';
import LoginPage from './pages/LoginPage'
import ScanPage from './pages/ScanPage'
import OrderPage from './pages/OrderPage'


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

function App() {
 
  return(
    <Router>
      <Switch>
        <Route exact path='/'>
          <OrderPage />
        </Route>
        {/* <Route path="/scan">
          <ScanPage />
        </Route> */}
        <Route path="/login">
          <LoginPage />
        </Route>
        {/* <Route path="/invoice/:order_id">
          <QRCodePage />
        </Route> */}
       
        {/* <Route path="/">
          <QRCodePage />
        </Route> */}

        {/* <Route path="/login2">
          <LoginPage />
        </Route> */}

        
        
        
      </Switch>
      
    </Router>
  )

}

export default App;
